window.ToggleMenu = function ToggleMenu() {
    var openmenu = document.getElementById('OpenMenu');
    var menu = document.getElementById('Menu');
    var overlayMenu = document.getElementById('OverlayMenu');
    openmenu.style.display = "flex";
    openmenu.classList.add("openmenuanimation");

    openmenu.classList.remove("closmenuanimation");
    overlayMenu.style.display = "flex";

}

window.closemenu = function closemenu() {
    var openmenu = document.getElementById('OpenMenu');
    var menu = document.getElementById('Menu');
    var overlayMenu = document.getElementById('OverlayMenu');


    openmenu.classList.remove("openmenuanimation");

    openmenu.classList.add("closmenuanimation");
    overlayMenu.style.display = "none";


}